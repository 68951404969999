//@ts-check
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import BrightClassfySvg from 'assets/logos/main/BrightClassfy'
import NoWorries from 'assets/svg/NoWorries'
import Welcome from 'assets/svg/Welcome'
import { executeMagicLogin } from 'api/auth'
import { useNotificationActions } from 'context/NotificationProvider'
import { useAuth } from 'context/AuthProvider'
import RingsSpinner from 'components/spinners/RingsSpinner'
import { H2, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import DotsSpinner from 'components/spinners/DotsSpinner'
import styles from './MagicLogin.module.css'

function MagicLogin() {
  const [step, setStep] = useState(1)
  const [isExecuting, setIsExecuting] = useState(true)
  const { setErrorMessage } = useNotificationActions()
  const { student, saveAuthentication } = useAuth()
  const { token } = useParams()

  const execute = () => {
    setIsExecuting(true)
    setStep(0)
    executeMagicLogin(token)
      .then(data => {
        if (!data || typeof data !== 'object') throw new Error('No auth data!')
        saveAuthentication(data)
        setTimeout(() => {
          setIsExecuting(false)
          setStep(1)
        }, 3000)
      })
      .catch(() =>
        setTimeout(() => {
          setIsExecuting(false)
          setErrorMessage({
            message:
              'Upps!, parece que no te identificamos. Ponte en contacto con soporte'
          })
          setStep(2)
        }, 3000)
      )
  }

  useEffect(() => {
    execute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const SVG = step === 3 ? Welcome : NoWorries
  return (
    <main className={styles.main}>
      <Link to='/login'>
        <BrightClassfySvg className={styles.classfyLogo} />
      </Link>
      <div className={styles.images}>
        <SVG className={styles.backgroundSvg} color='var(--sandground)' />
        <SVG className={styles.mainSvg} />
        {isExecuting && (
          <div className={styles.spinner}>
            <RingsSpinner />
          </div>
        )}
      </div>
      <div className={styles.innerContainer}>
        {step === 0 && (
          <>
            <H2 className={styles.h2}>
              Estamos verificando tu <span>identidad</span>
              <DotsSpinner />
            </H2>
          </>
        )}
        {step === 1 && (
          <>
            <H2 className={styles.h2}>¡Bienvenid@, {student.shortName}!</H2>
            <Paragraph className={styles.paragraph} type='body1Bold'>
              ¡Ya puedes acceder a tu plataforma favorita!
            </Paragraph>
            <Link to='/'>
              <Button label='Acceder' type='accent-secondary' />
            </Link>
          </>
        )}
        {step === 2 && (
          <>
            <H2 className={styles.h2}>
              Lo sentimos, parece que tu <span>enlace</span>
              <br /> de acceso ha <span>caducado</span>
            </H2>
            <Button
              type='secondary'
              label='Volver a intentar'
              onClick={execute}
            />
          </>
        )}
      </div>
    </main>
  )
}

export default MagicLogin
