//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import { hasPath, sortBy, take } from 'ramda'
import {
  getTestAttendanceCount,
  getScheduledAttendances
} from 'api/attendances'
import { getSampleTeachers } from 'api/teachers'
import { getAccessTimes, prepareAttendances, shuffle } from 'utils'
import { useAuth } from 'context/AuthProvider'
import useSubjects from 'hooks/useSubjects'
import AttendanceCarousel from 'components/carousel/AttendanceCarousel'
import TeacherCarousel from 'components/carousel/TeacherCarousel'
import BlogCarousel from 'components/carousel/BlogCarousel'
import YoutubeCarousel from 'components/carousel/YoutubeCarousel'
import TikTokCarousel from 'components/carousel/TikTokCarousel'
import styles from './Home.module.css'

const EXAMPLE_LIMIT = 10

const targetTeacherIds = process.env.REACT_APP_TEACHER_IDS || false

function Home() {
  const [state, setState] = useState({
    teachers: [],
    canBookAClass: false,
    scheduledAttendances: []
  })
  const { student } = useAuth()
  const { subjectsById } = useSubjects()

  const { teachers, canBookAClass, scheduledAttendances } = state

  const repetitiveCalls = useCallback(async () => {
    try {
      const scheduledAttendances = await getScheduledAttendances()

      const teachers = await getSampleTeachers()
        .then(teachers =>
          !targetTeacherIds
            ? teachers
            : teachers.filter(({ id }) => targetTeacherIds.includes(id))
        )
        .then(sortBy(teachers => !hasPath(['isOnline'], teachers)))
        .then(teachers => {
          const { prevAttendance, currentAttendance, nextAttendance } =
            prepareAttendances(scheduledAttendances)

          const firstTeachers = teachers.filter(({ id }) =>
            [
              prevAttendance?.teacherId,
              currentAttendance?.teacherId,
              nextAttendance?.teacherId
            ].includes(id)
          )
          const otherTeachers = teachers.filter(
            ({ id }) =>
              ![
                prevAttendance?.teacherId,
                currentAttendance?.teacherId,
                nextAttendance?.teacherId
              ].includes(id)
          )

          return take(
            EXAMPLE_LIMIT,
            firstTeachers.concat(shuffle(otherTeachers))
          ).map(teacher => {
            const currentDate = new Date()

            const { accessStart: currentStart, accessEnd: currentEnd } =
              getAccessTimes(currentAttendance)
            const { accessStart: nextStart } = getAccessTimes(nextAttendance)
            const { accessEnd: prevEnd } = getAccessTimes(prevAttendance)

            const canAccessToCurrentClass =
              currentAttendance?.teacherId === teacher.id &&
              currentDate.getTime() >= currentStart &&
              currentDate.getTime() <= currentEnd

            const canAccessToNextClass =
              nextAttendance?.teacherId === teacher.id &&
              nextStart <= currentDate.getTime()

            const canAccesToPrevClass =
              prevAttendance?.teacherId === teacher.id &&
              prevEnd >= currentDate.getTime()

            const isAvailable = () => {
              if (canAccessToCurrentClass) return true
              if (canAccessToNextClass) return true
              if (canAccesToPrevClass) return true
              return false
            }
            const getAttendance = () => {
              if (canAccessToCurrentClass) return currentAttendance
              if (canAccessToNextClass) return nextAttendance
              if (canAccesToPrevClass) return prevAttendance
              return { teacherId: teacher.id }
            }
            teacher.attendance = getAttendance()
            teacher.available = !targetTeacherIds ? isAvailable() : true

            return teacher
          })
        })

      // @ts-ignore
      setState(state => ({
        ...state,
        teachers,
        scheduledAttendances
      }))
    } catch (error) {
      console.error('Error in repetitiveCalls: ', error)
    }
  }, [])

  const fetchCommonData = useCallback(async () => {
    try {
      setState(state => ({
        ...state,
        canBookAClass: false //student.isNewUser && !testAttendanceCount
      }))
    } catch (error) {
      console.error('Error in fetchCommonData: ', error)
    }
  }, [])

  useEffect(() => {
    fetchCommonData()
    repetitiveCalls()
    if (targetTeacherIds) return
    const interval = setInterval(() => {
      repetitiveCalls()
    }, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [fetchCommonData, repetitiveCalls])

  return (
    <section className={styles.container}>
      <AttendanceCarousel
        targetTeacherIds={targetTeacherIds}
        scheduledAttendances={scheduledAttendances}
      />
      <TeacherCarousel
        teachers={teachers}
        canBookAClass={canBookAClass}
        subjectsById={subjectsById}
      />
      <BlogCarousel />
      <YoutubeCarousel />
      <TikTokCarousel />
      {/* <div className={styles.subjectSection}>
        <H2>Tus asignaturas 🧠</H2>
        <div className={styles.subjectList}>
        {[
          { level: 'Secundaria', currentPercentage: 15 },
          { level: 'Bachillerato' },
          { level: 'Primaria' }
        ].map(({ level, currentPercentage }) => (
          <SubjectCard level={level} currentPercentage={currentPercentage} />
        ))}
        </div>
      </div> */}
    </section>
  )
}

export default Home
