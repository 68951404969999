import React from 'react'
import Slider from 'react-slick'

const Carousel = ({
  className = '',
  autoplay = true,
  focusOnSelect = false,
  infinite = true,
  dots = true,
  children
}) => {
  return (
    <div className={['slider-container', className].join(' ')}>
      <Slider
        {...{
          variableWidth: true,
          adaptiveHeight: true,
          mobileFirst: true,
          dots,
          arrows: false,
          infinite,
          speed: 500,
          autoplay,
          focusOnSelect,
          autoplaySpeed: 5000,
          slidesToShow: 1,
          slidesToScroll: 1
        }}
      >
        {children}
      </Slider>
    </div>
  )
}

export default Carousel
