//@ts-check
import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import BrightClassfySvg from 'assets/logos/main/BrightClassfy'
import NoWorries from 'assets/svg/NoWorries'
import { requestMagicLink } from 'api/auth'
import { useNotificationActions } from 'context/NotificationProvider'
import Button from 'components/buttons/Button'
import { H2 } from 'components/typography'
import styles from './MagicLinkRequest.module.css'

function MagicLinkRequest() {
  const [step, setStep] = useState(0)
  const [email, setEmail] = useState('')
  const [isRecovering, setIsRecovering] = useState(false)
  const { setErrorMessage } = useNotificationActions()

  const handleSend = useCallback(() => {
    setIsRecovering(true)
    requestMagicLink(email)
      .then(() => setStep(1))
      .catch(() => setErrorMessage({}))
      .finally(() => setIsRecovering(false))
  }, [email, setErrorMessage])
  return (
    <main className={styles.main}>
      <Link to='/login'>
        <BrightClassfySvg className={styles.classfyLogo} />
      </Link>
      <div className={styles.images}>
        <NoWorries className={styles.backgroundSvg} color='var(--sandground)' />
        <NoWorries className={styles.mainSvg} />
      </div>
      <div className={styles.innerContainer}>
        {step ? (
          <H2 className={styles.h2}>
            Te hemos enviado un email con tu <br />
            <span>link mágico</span>.
          </H2>
        ) : (
          <>
            <input
              className={styles.email}
              name='email'
              type='text'
              placeholder='e-mail'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Button
              label='Enviar'
              type='secondary'
              onClick={handleSend}
              disabled={!email}
              loading={isRecovering}
            />
          </>
        )}
      </div>
    </main>
  )
}

export default MagicLinkRequest
