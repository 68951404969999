import React from 'react'
import ReactDOM from 'react-dom/client'
import adapter from 'webrtc-adapter'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import * as process from 'process'
import NotificationProvider from 'context/NotificationProvider'
import App from './App'
import './index.css'
import 'react-phone-input-2/lib/material.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

window.global = window
window.process = process
window.Buffer = []
console.log(adapter.browserDetails.browser)

Sentry.init({
  dsn: 'https://d2063ba45b2f40889b44a01b85f43404@o1069501.ingest.sentry.io/6064186',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
})
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <NotificationProvider>
    <App />
  </NotificationProvider>
)
