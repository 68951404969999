export const blogPosts = [
  {
    title:
      'Cómo estudiar un examen tipo test: técnicas efectivas para aprobar con éxito',
    background:
      'https://www.mundoestudiante.com/wp-content/uploads/2024/11/examen-tipo-test.png',
    category: 'Técnicas Estudio',
    link: 'https://www.mundoestudiante.com/como-estudiar-examenes-tipo-test/?_gl=1*19dsqq3*_up*MQ..*_ga*MTYxMDYzMjk3Ny4xNzMxNTk3ODE1*_ga_0DBH3W577L*MTczMTU5NzgxNC4xLjEuMTczMTU5NzgxOS4wLjAuMTY4Mjc3Nzk4OQ..&gclid=CjwKCAiA3Na5BhAZEiwAzrfagOc6CWdNCdlqGFvV3PhovQFMDzCJFZ-4mRC_xDczeXvUczgiFVw4SxoCANYQAvD_BwE'
  },
  {
    title:
      'El método hipotético-deductivo: el secreto detrás del progreso científico',
    background:
      'https://www.mundoestudiante.com/wp-content/uploads/2024/10/metodo-hipotetico-deductivo.png',
    category: 'Filosofía',
    link: 'https://www.mundoestudiante.com/metodo-hipotetico-deductivo/?_gl=1*19dsqq3*_up*MQ..*_ga*MTYxMDYzMjk3Ny4xNzMxNTk3ODE1*_ga_0DBH3W577L*MTczMTU5NzgxNC4xLjEuMTczMTU5NzgxOS4wLjAuMTY4Mjc3Nzk4OQ..&gclid=CjwKCAiA3Na5BhAZEiwAzrfagOc6CWdNCdlqGFvV3PhovQFMDzCJFZ-4mRC_xDczeXvUczgiFVw4SxoCANYQAvD_BwE'
  },
  {
    title: '«Be going to»: Cómo se forma y cuáles son sus usos',
    background:
      'https://www.mundoestudiante.com/wp-content/uploads/2024/10/be-going-to.png',
    category: 'Inglés',
    link: 'https://www.mundoestudiante.com/be-going-to-como-se-forma-y-cuales-son-sus-usos/?_gl=1*19dsqq3*_up*MQ..*_ga*MTYxMDYzMjk3Ny4xNzMxNTk3ODE1*_ga_0DBH3W577L*MTczMTU5NzgxNC4xLjEuMTczMTU5NzgxOS4wLjAuMTY4Mjc3Nzk4OQ..&gclid=CjwKCAiA3Na5BhAZEiwAzrfagOc6CWdNCdlqGFvV3PhovQFMDzCJFZ-4mRC_xDczeXvUczgiFVw4SxoCANYQAvD_BwE'
  },
  {
    title:
      'La generación del 14 y el Novecentismo: renovación cultural y literaria en España',
    background:
      'https://www.mundoestudiante.com/wp-content/uploads/2024/10/generacion-del-14-y-el-Novecentismo.png',
    category: 'Lengua',
    link: 'https://www.mundoestudiante.com/la-generacion-del-14-y-el-novecentismo-renovacion-cultural-y-literaria-en-espana/?_gl=1*19dsqq3*_up*MQ..*_ga*MTYxMDYzMjk3Ny4xNzMxNTk3ODE1*_ga_0DBH3W577L*MTczMTU5NzgxNC4xLjEuMTczMTU5NzgxOS4wLjAuMTY4Mjc3Nzk4OQ..&gclid=CjwKCAiA3Na5BhAZEiwAzrfagOc6CWdNCdlqGFvV3PhovQFMDzCJFZ-4mRC_xDczeXvUczgiFVw4SxoCANYQAvD_BwE'
  },
  {
    title: 'Nueva Pau. Novedades y cambios clave en la EvAU 2024-25',
    background:
      'https://www.mundoestudiante.com/wp-content/uploads/2024/10/pau-cambios-evau-2024-2025.png',
    category: 'Selectividad',
    link: 'https://www.mundoestudiante.com/pau-evau-2024-25/?_gl=1*19dsqq3*_up*MQ..*_ga*MTYxMDYzMjk3Ny4xNzMxNTk3ODE1*_ga_0DBH3W577L*MTczMTU5NzgxNC4xLjEuMTczMTU5NzgxOS4wLjAuMTY4Mjc3Nzk4OQ..&gclid=CjwKCAiA3Na5BhAZEiwAzrfagOc6CWdNCdlqGFvV3PhovQFMDzCJFZ-4mRC_xDczeXvUczgiFVw4SxoCANYQAvD_BwE'
  },
  {
    title: 'El Neolítico en la Península Ibérica',
    background:
      'https://www.mundoestudiante.com/wp-content/uploads/2024/10/Neolitico-en-la-Peninsula-Iberica.png',
    category: 'Historia',
    link: 'https://www.mundoestudiante.com/neolitico-en-la-peninsula-iberica/?_gl=1*19dsqq3*_up*MQ..*_ga*MTYxMDYzMjk3Ny4xNzMxNTk3ODE1*_ga_0DBH3W577L*MTczMTU5NzgxNC4xLjEuMTczMTU5NzgxOS4wLjAuMTY4Mjc3Nzk4OQ..&gclid=CjwKCAiA3Na5BhAZEiwAzrfagOc6CWdNCdlqGFvV3PhovQFMDzCJFZ-4mRC_xDczeXvUczgiFVw4SxoCANYQAvD_BwE'
  },
  {
    title: 'Qué son los sectores primario, secundario, terciario',
    background:
      'https://www.mundoestudiante.com/wp-content/uploads/2024/09/sectores-primario-secundario-terciario.png',
    category: 'Economía',
    link: 'https://www.mundoestudiante.com/que-son-los-sectores-primario-secundario-terciario/?_gl=1*19dsqq3*_up*MQ..*_ga*MTYxMDYzMjk3Ny4xNzMxNTk3ODE1*_ga_0DBH3W577L*MTczMTU5NzgxNC4xLjEuMTczMTU5NzgxOS4wLjAuMTY4Mjc3Nzk4OQ..&gclid=CjwKCAiA3Na5BhAZEiwAzrfagOc6CWdNCdlqGFvV3PhovQFMDzCJFZ-4mRC_xDczeXvUczgiFVw4SxoCANYQAvD_BwE'
  },
  {
    title: 'Cuándo se usa el Past Simple y el Past Continuous',
    background:
      'https://www.mundoestudiante.com/wp-content/uploads/2024/09/cuando-se-usa-past-simple-y-past-continuous.png',
    category: 'Inglés',
    link: 'https://www.mundoestudiante.com/cuando-se-usa-el-past-simple-y-el-past-continuous/?_gl=1*19dsqq3*_up*MQ..*_ga*MTYxMDYzMjk3Ny4xNzMxNTk3ODE1*_ga_0DBH3W577L*MTczMTU5NzgxNC4xLjEuMTczMTU5NzgxOS4wLjAuMTY4Mjc3Nzk4OQ..&gclid=CjwKCAiA3Na5BhAZEiwAzrfagOc6CWdNCdlqGFvV3PhovQFMDzCJFZ-4mRC_xDczeXvUczgiFVw4SxoCANYQAvD_BwE'
  },
  {
    title: 'Las teorías psicológicas de la personalidad',
    background:
      'https://www.mundoestudiante.com/wp-content/uploads/2024/09/teorias-psicologicas-de-la-personalidad.png',
    category: 'Filosofía',
    link: 'https://www.mundoestudiante.com/las-teorias-psicologicas-de-la-personalidad/?_gl=1*19dsqq3*_up*MQ..*_ga*MTYxMDYzMjk3Ny4xNzMxNTk3ODE1*_ga_0DBH3W577L*MTczMTU5NzgxNC4xLjEuMTczMTU5NzgxOS4wLjAuMTY4Mjc3Nzk4OQ..&gclid=CjwKCAiA3Na5BhAZEiwAzrfagOc6CWdNCdlqGFvV3PhovQFMDzCJFZ-4mRC_xDczeXvUczgiFVw4SxoCANYQAvD_BwE'
  },
  {
    title: 'Qué son los determinantes y su clasificación',
    background:
      'https://www.mundoestudiante.com/wp-content/uploads/2024/09/los-determinantes.png',
    category: 'Lengua',
    link: 'https://www.mundoestudiante.com/que-son-los-determinantes-y-su-clasificacion/?_gl=1*19dsqq3*_up*MQ..*_ga*MTYxMDYzMjk3Ny4xNzMxNTk3ODE1*_ga_0DBH3W577L*MTczMTU5NzgxNC4xLjEuMTczMTU5NzgxOS4wLjAuMTY4Mjc3Nzk4OQ..&gclid=CjwKCAiA3Na5BhAZEiwAzrfagOc6CWdNCdlqGFvV3PhovQFMDzCJFZ-4mRC_xDczeXvUczgiFVw4SxoCANYQAvD_BwE'
  }
]

export const youTubeVideos = [
  {
    title: 'Lente - ejercicio 1',
    background: 'https://picsum.photos/500/500?random=1.webp',
    category: 'Física',
    link: 'https://www.youtube.com/embed/yQpgiFBvkPo?rel=0',
    detail: '7:35'
  },
  {
    title: 'Movimiento armonico simple - ejercicio 4',
    background: 'https://picsum.photos/500/500?random=2.webp',
    category: 'Física',
    link: 'https://www.youtube.com/embed/iciL4LTXO3I?rel=0',
    detail: '2:42'
  },
  {
    title: 'Movimiento rectilineo uniformemente acelerado - ejercicio 1',
    background: 'https://picsum.photos/500/500?random=3.webp',
    category: 'Física',
    link: 'https://www.youtube.com/embed/pLe7Kn0Tlko?rel=0',
    detail: '3:30'
  },
  {
    title: 'Movimiento rectilineo uniformemente acelerado - ejercicio 2',
    background: 'https://picsum.photos/500/500?random=4.webp',
    category: 'Física',
    link: 'https://www.youtube.com/embed/cug8t-p4gRM?rel=0',
    detail: '4:07'
  },
  {
    title: 'Cociente binómica - ejercicio 11',
    background: 'https://picsum.photos/500/500?random=5.webp',
    category: 'Matemáticas',
    link: 'https://www.youtube.com/embed/ov9Ii44ZvV4?rel=0',
    detail: '4:40'
  },
  {
    title: 'Integrales inmediatas - ejercicio 4',
    background: 'https://picsum.photos/500/500?random=6.webp',
    category: 'Matemáticas',
    link: 'https://www.youtube.com/embed/DMqTjlKpl4I?rel=0',
    detail: '6:20'
  },
  {
    title: 'Integrales inmediatas - ejercicio 5',
    background: 'https://picsum.photos/500/500?random=7.webp',
    category: 'Matemáticas',
    link: 'https://www.youtube.com/embed/FMIjo83IQd4?rel=0',
    detail: '6:11'
  },
  {
    title: 'Integrales inmediatas - ejercicio 13',
    background: 'https://picsum.photos/500/500?random=8.webp',
    category: 'Matemáticas',
    link: 'https://www.youtube.com/embed/Cd1TZXGoAlk?rel=0',
    detail: '3:28'
  },
  {
    title: 'Progresión aritmética - ejercicio 6',
    background: 'https://picsum.photos/500/500?random=9.webp',
    category: 'Matemáticas',
    link: 'https://www.youtube.com/embed/gCKt1GFHPVQ?rel=0',
    detail: '3:01'
  },
  {
    title: 'Sistema logarítmico - ejercicio 13',
    background: 'https://picsum.photos/500/500?random=10.webp',
    category: 'Matemáticas',
    link: 'https://www.youtube.com/embed/1-clpOBVIQk?rel=0',
    detail: '4:18'
  }
]

export const tikTokVideos = [
  {
    title: '¡El equipo de mundoestudiante también tiene ritmo! 🔥🙌',
    background: 'https://picsum.photos/500/500?random=11.webp',
    category: 'Equipo',
    link: 'https://www.tiktok.com/@mundoestudiante/video/7434259375209925921',
    detail: '00:14'
  },
  {
    title: '🎃 Este Halloween no solo estudiamos… también asustamos 👻',
    background: 'https://picsum.photos/500/500?random=12.webp',
    category: 'Equipo',
    link: 'https://www.tiktok.com/@mundoestudiante/video/7431994842730876193',
    detail: '00:20'
  },
  {
    title:
      '🎃 Atenuar las luces y abrir los libros… ¡mundoestudiante ha entrado oficialmente en modo Halloween!',
    background: 'https://picsum.photos/500/500?random=13.webp',
    category: 'Consejos',
    link: 'https://www.tiktok.com/@mundoestudiante/video/7431942652850883873',
    detail: '00:10'
  },
  {
    title:
      '¿Alguna vez te preguntaste por qué el 1 siempre tiene que ser el centro de atención? 🤔',
    background: 'https://picsum.photos/500/500?random=14.webp',
    category: 'Educación',
    link: 'https://www.tiktok.com/@mundoestudiante/video/7430833062188993825',
    detail: '00:36'
  },
  {
    title:
      '‼️No te olvides que hoy cambia la hora ⏰… pero no entres en bucle! 🔄',
    background: 'https://picsum.photos/500/500?random=15.webp',
    category: 'Educación',
    link: 'https://www.tiktok.com/@mundoestudiante/video/7430156730903956769',
    detail: '00:24'
  },
  {
    title: '¿Sabías que hay un número mágico? 🔢✨ La constante de Kaprekar',
    background: 'https://picsum.photos/500/500?random=16.webp',
    category: 'Motivación',
    link: 'https://www.tiktok.com/@mundoestudiante/video/7428956119193767200',
    detail: '00:33'
  },
  {
    title: '📊🌍 ¡Día Mundial de la Estadística!',
    background: 'https://picsum.photos/500/500?random=17.webp',
    category: 'Educación',
    link: 'https://www.tiktok.com/@mundoestudiante/video/7427933317237853472',
    detail: '00:36'
  },
  {
    title: '3 consejos para mejorar el estado de ánimo',
    background: 'https://picsum.photos/500/500?random=18.webp',
    category: 'Consejos',
    link: 'https://www.tiktok.com/@mundoestudiante/video/7424206385014902049',
    detail: '00:21'
  },
  {
    title:
      'Sabemos que acceder a la universidad es el primer escalón hacia tus sueños 🚀🎓',
    background: 'https://picsum.photos/500/500?random=19.webp',
    category: 'Motivación',
    link: 'https://www.tiktok.com/@mundoestudiante/video/7346219921971350817',
    detail: '00:30'
  },
  {
    title:
      'En mundoestudiante, la teoría del esfuerzo es nuestra clave para alcanzar el éxito académico. 💡💪',
    background: 'https://picsum.photos/500/500?random=20.webp',
    category: 'Consejos',
    link: 'https://www.tiktok.com/@mundoestudiante/video/7339910690687290657',
    detail: '01:28'
  }
]

export function getElementsBy(day, list = []) {
  const startIndex = ((day || 1) - 1) % list.length
  return list
    .slice(startIndex, startIndex + 4)
    .concat(list.slice(0, Math.max(0, startIndex + 4 - list.length)))
}

export const getBlogPosts = (day = new Date().getDay()) =>
  getElementsBy(day, blogPosts)

export const getYouTubeVideos = (day = new Date().getDay()) =>
  getElementsBy(day, youTubeVideos)

export const getTikTokVideos = (day = new Date().getDay()) =>
  getElementsBy(day, tikTokVideos)
