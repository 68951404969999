import { GET, POST } from './index'

export const login = (data = {}) =>
  POST({ url: 'auth/students/login', data, useAuth: false })

/**
 *
 * @param {{full: boolean}} data
 */
export const fetchMe = (data = {}) => {
  return GET({ url: 'students/account/me' + (data.full ? '/full' : '') })
}

export const register = (data = {}) =>
  POST({ url: 'auth/students/register', data, useAuth: false })

export const recoverPassword = (email = '') =>
  POST({
    url: 'auth/students/recover-access',
    data: { email },
    useAuth: false
  })

export const requestMagicLink = (email = '') =>
  POST({
    url: 'auth/students/request-magic-link',
    data: { email },
    useAuth: false
  })

export const executeMagicLogin = (token = '') =>
  POST({
    url: 'auth/students/magic-login',
    data: { token },
    useAuth: false
  })

export const executeVerification = token =>
  POST({
    url: 'auth/students/validate-verification-token',
    data: { token },
    useAuth: false
  })

export const verifyAccess = (token, password) =>
  POST({
    url: 'auth/students/verify-access',
    data: { token, password },
    useAuth: false
  })
