const textByEnvironment = {
  development: 'Entorno de desarrollo 🛠',
  preproduction: 'Entorno de pruebas 👨‍🔬'
}
const config = {
  environmentTag:
    textByEnvironment[process.env.REACT_APP_ENV] ||
    textByEnvironment[process.env.NODE_ENV],
  accessTokenKey: process.env.REACT_APP_ACCESS_TOKEN_KEY || 'access_token',
  host: window.location.host,
  apiURL: process.env.REACT_APP_API_URL || 'http://localhost:5000',
  hiddenMenuItems: process.env.REACT_APP_HIDDEN_MENU_ITEMS || '',
  hide: process.env.REACT_APP_HIDE,
  logWebRTC: process.env.REACT_APP_LOG_WEB_RTC || '',
  videoTourURL:
    process.env.REACT_APP_VIDEO_TOUR_URL ||
    'https://www.youtube.com/embed/2O8HqTLT5sw', //TODO
  isVideoTourViewedKey:
    process.env.REACT_APP_IS_TUTORIAL_VIEWED_KEY || 'isVideoTourViewed',
  hasMediaPermissionsKey:
    process.env.REACT_APP_HAS_MEDIA_PERMISSIONS_KEY || 'hasMediaPermissions',
  selectedMediaKey: process.env.REACT_APP_SELECTED_MEDIA_KEY || 'selectedMedia',
  whatsAppNumber: process.env.REACT_APP_WHATS_APP_NUMBER || '34648140199',
  stripe: {
    token:
      process.env.REACT_APP_STRIPE_TOKEN ||
      'pk_test_51JmEBlLn33caCtr1yo3cuTTOQcZ3k5UqSV1IFrsV4salTPsWvnOpcbTkX4OEBg7wVYyWNEEjPoz0LYDMrKlBaVbK002YGJjQtj'
  },
  bookingCancellationTime:
    process.env.REACT_APP_BOOKING_CANCELLATION_TIME || 24,
  google: {
    clientId:
      process.env.REACT_APP_GOOGLE_CLIENT_ID ||
      '301002453673-tfh9mfa7j6iumb45cl6s21pe9lboku2a.apps.googleusercontent.com',
    apiKey:
      process.env.REACT_APP_GOOGLE_API_KEY ||
      'AIzaSyDc55ewnffHuynmgRcML2pFtM_CPpFlmvs',
    discoveryDocs: {
      calendar: [
        'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'
      ]
    },
    scopes: { calendar: 'https://www.googleapis.com/auth/calendar' }
  },
  externalServicesToken: process.env.REACT_APP_EXTERNAL_SERVICES_TOKEN
}

export default config
