//@ts-check
import React from 'react'
import { Navigate, useOutletContext } from 'react-router-dom'
import config from 'config'
import AlmostReady from 'assets/svg/AlmostReady'
import BrainOn from 'assets/svg/BrainOn'
import useLocalStorage from 'hooks/useLocalStorage'
import { H3, H4Light } from 'components/typography'
import Button from 'components/buttons/Button'
import ClassroomReconnecting from 'components/academy/classroom/ClassroomReconnecting'
import MediaSetup from 'components/modals/MediaSetup'
import styles from './Academy.module.css'

function Academy() {
  /**@type {import('hooks/useWebRTC').WebRTCState} */
  const {
    teachers = [],
    isReconnecting,
    activeTeacher,
    isTest,
    isWaiting,
    saveSelectedMedia,
    handleActiveTeacher
  } = useOutletContext()

  const { item: isVideoTourViewed } = useLocalStorage(
    config.isVideoTourViewedKey,
    false
  )
  const { item: hasMediaPermissions } = useLocalStorage(
    config.hasMediaPermissionsKey,
    false
  )

  // if (!isVideoTourViewed || !hasMediaPermissions)
  //   return <Navigate to='/academy/video-tour' />

  if (!hasMediaPermissions) return <Navigate to='/academy/media-permissions' />

  const teacher = teachers.find(({ _id }) => _id === activeTeacher)
  const disabled = teachers.length === 0 || !teacher
  const getLabel = () => {
    if (disabled) return 'Esperando al profe...'
    if (isTest) return 'Pedir acceso'
    return 'Entrar'
  }
  return (
    <section className={styles.section}>
      {isReconnecting ? (
        <ClassroomReconnecting
          classNames={{ container: styles.reconnectingContainer }}
        />
      ) : (
        <div className={styles.container}>
          {!teacher ? (
            <AlmostReady className={styles.svg} />
          ) : (
            <BrainOn className={styles.svg} color='var(--seadapted)' />
          )}
          {!teacher ? (
            <H3>
              ¡La rapidez no es siempre la mejor solución,
              <br /> la paciencia sí!
              <br />
              Estamos conectándote con la plataforma,
              <br /> no nos queda nada...
            </H3>
          ) : isWaiting ? (
            <H3>
              ¡Tu profe está en línea!
              <br />
              Pronto te atenderá
              <br />⏱
            </H3>
          ) : (
            <H3>
              ¡Tu profe está en línea!
              <br />
              {isTest
                ? 'Solicita entrar en tu clase de prueba 📚'
                : 'Disfruta de tu clase 🙌'}
            </H3>
          )}

          <Button
            label={getLabel()}
            onClick={() => handleActiveTeacher(teacher?._id)}
            loading={isWaiting}
            disabled={disabled || isWaiting}
          />
          {!isWaiting && (
            <div className={styles.fabIconSection}>
              <H4Light>¿Quieres ajustar 🎤 📸?</H4Light>
              <MediaSetup
                className={styles.fabIcon}
                fabIconColor='var(--seadapted)'
                onSave={saveSelectedMedia}
              />
            </div>
          )}
        </div>
      )}
    </section>
  )
}

export default Academy
