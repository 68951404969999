import React from 'react'
import { getBlogPosts } from 'utils/homeData'
import { H2 } from 'components/typography'
import BlogCard from 'components/cards/highlight/BlogCard'
import HighlightCard from 'components/cards/highlight/HighlightCard'
import Carousel from './Carousel'
import styles from './BlogCarousel.module.css'

const BlogCarousel = ({ posts = getBlogPosts() }) => {
  return (
    <div className={styles.container}>
      <H2>Novedades del blog 🗞️</H2>
      <Carousel className={styles.carousel}>
        {posts.map(({ title, background, category, link }, i) => (
          <div className={styles.slide} key={i}>
            <BlogCard
              index={i}
              imgUrl={background}
              title={title}
              href={link}
              category={category}
            />
          </div>
        ))}
        <div className={styles.slide} key='last'>
          <HighlightCard
            title='Descubre más en nuestro Blog'
            buttonProps={{
              size: 'tiny',
              type: 'accent-tertiary',
              href: 'https://www.mundoestudiante.com/blog/?_gl=1*xfb0bv*_up*MQ..*_ga*MTYxMDYzMjk3Ny4xNzMxNTk3ODE1*_ga_0DBH3W577L*MTczMTU5NzgxNC4xLjAuMTczMTU5NzgxNC4wLjAuMTY4Mjc3Nzk4OQ..&gclid=CjwKCAiA3Na5BhAZEiwAzrfagOc6CWdNCdlqGFvV3PhovQFMDzCJFZ-4mRC_xDczeXvUczgiFVw4SxoCANYQAvD_BwE'
            }}
          />
        </div>
      </Carousel>
    </div>
  )
}

export default BlogCarousel
