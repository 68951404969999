//@ts-check
import { useCallback, useEffect, useMemo, useState } from 'react'
import { assoc, assocPath, isEmpty, omit, uniq } from 'ramda'
import {
  getNextAvailableTestAttendance,
  getTestAttendanceCount,
  saveTestAttendance
} from 'api/attendances'
import {
  getAvailableTestTeachers,
  getTeacherSchedulesByDate
} from 'api/teachers'
import { useAuth } from 'context/AuthProvider'
import { useNotificationActions } from 'context/NotificationProvider'
import { AttendanceTypes } from 'utils/constants'
import { DAY_NAME_BY_NUMBER, makeCustomDate } from 'utils/date'

export default function useNewlyRegistered() {
  const [state, setState] = useState({
    step: 0,
    subjectsById: {},
    teachers: [],
    schedules: {},
    availableTeacherHours: { am: [], pm: [] },
    showConfirmModal: false,
    showHelpModal: false,
    isSaving: false,
    isFetchingSchedules: false,
    canBookAClass: true,
    visibleCalendarMonth: new Date(),
    form: {
      subjectId: '',
      teacherId: '',
      selectedDate: new Date(new Date().setHours(0, 0, 0, 0)),
      startHour: '',
      endHour: ''
    },
    showSecondTestAlert: false,
    secondTestAttendanceOptions: [],
    selectedOption: { teacherId: '', subjectId: '' },
    showSecondTestConfirmModal: false
  })
  const { student } = useAuth()
  const { setErrorMessage } = useNotificationActions()
  const { isNewUser } = student
  const { form } = state

  const subjectFilter = useCallback(
    (subjects = []) =>
      subjects.filter(subject =>
        student.trial?.subjectIds?.includes(subject.id)
      ),
    [student]
  )
  const extractSubjectsById = useCallback(
    subjectsById => setState(state => ({ ...state, subjectsById })),
    []
  )
  const handleFormChange = useCallback(e => {
    const { name, value } = e.target
    setState(assocPath(['form', name], value))
  }, [])

  const targetTeacherIds = useMemo(() => {
    let teacherIds = []
    if (form.subjectId)
      teacherIds = state.teachers
        .filter(teacher => teacher?.subjects?.includes(form.subjectId))
        .map(teacher => teacher.id)
    return teacherIds
  }, [form.subjectId, state.teachers])

  const checkDisabledDate = useCallback(
    date => {
      const aux = Object.values(state.schedules).map(groupedSchedulesByDate => {
        const targetDate = groupedSchedulesByDate[date.getTime()]
        if (!targetDate) true
        return isEmpty(targetDate?.hours || [])
      })
      return !aux.includes(false)
    },
    [state.schedules]
  )

  const handleChangeVisibleMonth = e =>
    setState(prevState => {
      const newVisibleCalendarMonth = e.target.value
      let targetDate = new Date(new Date().setHours(0, 0, 0, 0))

      if (targetDate.getMonth() != newVisibleCalendarMonth.getMonth())
        targetDate = new Date(
          new Date(
            newVisibleCalendarMonth.getFullYear(),
            newVisibleCalendarMonth.getMonth(),
            1
          ).setHours(0, 0, 0, 0)
        )

      return {
        ...prevState,
        visibleCalendarMonth: newVisibleCalendarMonth,
        form: { ...prevState.form, selectedDate: targetDate }
      }
    })

  const handleSelectTeacher = useCallback(
    teacherId => {
      handleFormChange({
        target: { name: 'teacherId', value: teacherId }
      })
      setState(assoc('showConfirmModal', true))
    },
    [handleFormChange]
  )

  const handleSave = useCallback(() => {
    setState(assoc('isSaving', true))
    saveTestAttendance({
      ...omit(['selectedDate', 'startHour', 'endHour'], form),
      classStart: makeCustomDate(form.selectedDate, form.startHour),
      classEnd: makeCustomDate(form.selectedDate, form.endHour),
      type: AttendanceTypes.PRUEBA
    })
      .then(() => {
        setState(state => ({
          ...state,
          showConfirmModal: false,
          step: state.step + 1
        }))
      })
      .catch(e => {
        console.error('Error saving test attendance: ', e)
        setErrorMessage({
          title: 'Error en la reserva',
          message:
            'Parece que ha habido un problema, inténtalo con otra configuración o más tarde'
        })
      })
      .finally(() => setState(assoc('isSaving', false)))
  }, [form, setErrorMessage])

  const handleSelectSecondTestAttendance = selectedOption =>
    setState(prevState => ({
      ...prevState,
      selectedOption,
      showSecondTestConfirmModal: true
    }))

  const handleSaveSecondTestAttendance = useCallback(() => {
    const { teacherId, subjectId } = state.selectedOption
    setState(assoc('isSaving', true))

    saveTestAttendance({
      teacherId,
      subjectId,
      classStart: form.selectedDate, //calculate in back
      type: AttendanceTypes.PRUEBA
    })
      .then(() => {
        setState(prevState => ({
          ...prevState,
          showSecondTestConfirmModal: false,
          step: prevState.step + 1
        }))
      })
      .catch(e => {
        console.error('Error saving test attendance: ', e)
        setErrorMessage({
          title: 'Error en la reserva',
          message:
            'Parece que ha habido un problema, inténtalo con otra configuración o más tarde'
        })
      })
      .finally(() => setState(assoc('isSaving', false)))
  }, [state.selectedOption, form.selectedDate, setErrorMessage])

  const handleConfirmShowSecondTestOptions = () =>
    setState(prevState => ({
      ...prevState,
      showSecondTestAlert: false,
      step: 3
    }))

  const filteredTeachers = useMemo(
    () =>
      state.teachers.filter(teacher => {
        if (!teacher.subjects.includes(form.subjectId)) return

        const teacherSchedule =
          state.schedules[teacher.id]?.[new Date(form.selectedDate).getTime()]
        if (!teacherSchedule) return
        const targetHour = `${form.startHour}-${form.endHour}`
        if (teacherSchedule.hours.includes(targetHour)) return teacher
      }),
    [
      form.endHour,
      form.selectedDate,
      form.startHour,
      form.subjectId,
      state.schedules,
      state.teachers
    ]
  )

  const extractAvailableTeacherHours = useCallback(() => {
    const { am = [], pm = [] } = Object.values(state.schedules).reduce(
      (acc, groupedSchedulesByDate) => {
        const targetDate = groupedSchedulesByDate[form.selectedDate.getTime()]

        if (!targetDate) return acc

        const allHours = targetDate.hours
        acc.am = acc.am.concat(
          ...allHours.filter(hour => hour.split(':')[0] < 15)
        )
        acc.pm = acc.pm.concat(
          ...allHours.filter(hour => hour.split(':')[0] >= 15)
        )
        return acc
      },
      { am: [], pm: [] }
    )
    setState(state => ({
      ...state,
      availableTeacherHours: {
        am: uniq(am.sort()),
        pm: uniq(pm.sort())
      }
    }))
  }, [form.selectedDate, state.schedules])

  useEffect(() => {
    extractAvailableTeacherHours()
  }, [extractAvailableTeacherHours])

  useEffect(() => {
    handleFormChange({ target: { name: 'startHour', value: '' } })
    handleFormChange({ target: { name: 'endHour', value: '' } })
  }, [form.selectedDate, form.subjectId, handleFormChange])

  useEffect(() => {
    if (!isEmpty(student))
      getAvailableTestTeachers({
        eligible: true,
        subjects: { $in: student.trial?.subjectIds || [], useCleanedId: true }
      })
        .then(teachers => setState(state => ({ ...state, teachers })))
        .catch(e => {
          console.error('Error fetching teachers: ', e)
        })
  }, [student])

  useEffect(() => {
    setState(assoc('isFetchingSchedules', true))
    if (isEmpty(targetTeacherIds))
      return setState(state => ({
        ...state,
        isFetchingSchedules: false,
        schedules: {}
      }))

    getTeacherSchedulesByDate({
      teacherIds: targetTeacherIds,
      date: state.visibleCalendarMonth
    })
      .then(schedules => {
        if (!schedules || isEmpty(schedules))
          return setState(state => ({
            ...state,
            isFetchingSchedules: false,
            schedules: {}
          }))
        setState(prevState => ({
          ...prevState,
          schedules,
          isFetchingSchedules: false
        }))
      })
      .catch(e => {
        setState(assoc('isFetchingSchedules', false))
        console.error('Error fetching schedules: ', e)
      })
  }, [state.visibleCalendarMonth, targetTeacherIds])
  useEffect(() => {
    getTestAttendanceCount().then(testAttendanceCount =>
      setState(state => ({
        ...state,
        canBookAClass: isNewUser && !testAttendanceCount
      }))
    )
  }, [isNewUser])

  useEffect(() => {
    if (state.step === 0 && state.secondTestAttendanceOptions.length > 0)
      setState(prevState => ({
        ...prevState,
        step: state.secondTestAttendanceOptions.length > 0 ? 3 : prevState.step
      }))
  }, [state.step, state.secondTestAttendanceOptions])
  useEffect(() => {
    if (state.step === 2)
      getNextAvailableTestAttendance().then(secondTestAttendanceOptions =>
        setState(prevState => ({
          ...prevState,
          secondTestAttendanceOptions,
          showSecondTestAlert: secondTestAttendanceOptions.length > 0
        }))
      )
  }, [state.step])

  useEffect(() => {
    getNextAvailableTestAttendance()
      .then(secondTestAttendanceOptions =>
        setState(
          assoc('secondTestAttendanceOptions', secondTestAttendanceOptions)
        )
      )
      .catch(_ => {})
  }, [])

  return {
    state,
    form,
    student,
    filteredTeachers,
    subjectFilter,
    extractSubjectsById,
    handleChangeVisibleMonth,
    handleFormChange,
    checkDisabledDate,
    handleNext: () =>
      setState(prevState => ({ ...prevState, step: prevState.step + 1 })),
    handleBack: () =>
      setState(prevState => ({ ...prevState, step: prevState.step - 1 })),
    handleSelectTeacher,
    handleShowHelpModal: () => setState(assoc('showHelpModal', true)),
    handleCloseHelpModal: () => setState(assoc('showHelpModal', false)),
    handleSelectSecondTestAttendance,
    handleCloseConfirmModal: () => setState(assoc('showConfirmModal', false)),
    handleSave,
    handleConfirmShowSecondTestOptions,
    handleCloseSecondTestAlert: () =>
      setState(assoc('showSecondTestAlert', false)),
    handleCloseSecondTestConfirmModal: () =>
      setState(assoc('showSecondTestConfirmModal', false)),
    handleSaveSecondTestAttendance
  }
}
