// @ts-check
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { noop } from 'utils'

const NotificationContext = createContext({
  title: '',
  message: '',
  type: '',
  children: null,
  ms: 6000
})

const NotificationActionsContext = createContext({
  clear: noop,
  setErrorMessage: noop,
  setSuccessMessage: noop,
  setInfoMessage: noop,
  setWarningMessage: noop
})

function NotificatioProvider({ children }) {
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [type, setType] = useState('')
  const [notificationChildren, setNotificationChildren] = useState(null)
  const [ms, setMs] = useState(0)

  const update = ({ title, message, type, children, ms }) => {
    setTitle(title)
    setMessage(message)
    setType(type)
    setNotificationChildren(children)
    setMs(ms)
  }
  const value = useMemo(
    () => ({
      clear: () =>
        update({ title: '', message: '', type: '', children: null, ms: 0 }),
      setErrorMessage: ({
        title = 'Error',
        message = 'Se ha producido un error',
        children,
        ms = 7000
      }) => update({ title, message, type: 'error', children, ms }),
      setSuccessMessage: ({
        title = 'Éxito',
        message = 'Guardado correctamente',
        children,
        ms = 6000
      }) => update({ title, message, type: 'success', children, ms }),
      setInfoMessage: ({
        title = 'Información',
        message,
        children,
        ms = 6000
      }) => update({ title, message, type: 'info', children, ms }),
      setWarningMessage: ({
        title = 'Atención',
        message,
        children,
        ms = 6000
      }) => update({ title, message, type: 'warning', children, ms })
    }),
    []
  )

  useEffect(() => {
    let id = setTimeout(() => {
      setMs(0)
    }, ms)
    return () => {
      clearTimeout(id)
    }
  }, [ms])
  return (
    <NotificationContext.Provider
      value={{ title, message, type, children: notificationChildren, ms }}
    >
      <NotificationActionsContext.Provider value={value}>
        {children}
      </NotificationActionsContext.Provider>
    </NotificationContext.Provider>
  )
}

export default NotificatioProvider

/**
 *
 * @returns {{
 * title: string,
 * message: string,
 * type: import('components/notifications/Notification').NotificationProps['type'] | string
 * children: any
 * ms: number
 * }}
 */
export const useNotification = () => useContext(NotificationContext)

/**
 * @typedef CommomNotificationParams
 * @property {string} title
 * @property {string | any} message
 * @property {any} [children]
 * @property {number} [ms]
 */
/**
 *
 * @returns {{
 * clear: VoidFunction
 * setErrorMessage: ({title, message, children, ms}:Partial<CommomNotificationParams>) => void
 * setSuccessMessage: ({title, message, children, ms}:Partial<CommomNotificationParams>) => void
 * setInfoMessage: ({title, message, children, ms}:Partial<CommomNotificationParams>) => void
 * setWarningMessage: ({title, message, children, ms}:Partial<CommomNotificationParams>) => void
 * }}
 */
export const useNotificationActions = () =>
  useContext(NotificationActionsContext)
