import { makeCustomQueryAndProject } from 'utils/queries'
import { GET } from './index'

export const findTeachers = (query = {}, project = []) =>
  GET({
    url: makeCustomQueryAndProject(query, project, 'students/teacher-info/list')
  })

export const getAvailableHoursBy = (date, teacherId) =>
  GET({
    url: `students/teacher-info/available-hours-by?date=${new Date(
      date
    ).toDateString()}&teacherId=${teacherId}`
  })

export const getTeacherSchedulesByDate = ({
  teacherIds = [],
  date = new Date(new Date().setHours(0, 0, 0, 0))
}) =>
  GET({
    url: makeCustomQueryAndProject(
      { teacherIds, date },
      [],
      'students/teacher-info/schedules'
    )
  })

export const getAvailableTestTeachers = (query = {}) =>
  GET({
    url: makeCustomQueryAndProject(
      query,
      [],
      'students/teacher-info/available-test-teachers'
    )
  })

export const getSampleTeachers = () =>
  GET({
    url: 'students/teacher-info/sample-teachers'
  })
