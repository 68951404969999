//@ts-check
import CONFIG from 'config'
import { sort } from 'ramda'
import { AttendanceTypes, GracePeriod } from './constants'

export const getAuthenticationToken = () => {
  return localStorage.getItem(CONFIG.accessTokenKey)
}

export function clearLocalStorageExcept(keysToKeep = []) {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (!keysToKeep.includes(key)) {
      localStorage.removeItem(key)
    }
  }
}

/**
 *
 * @param {{target:{name: string | array, value: any}}} e
 * @returns {{path: string[], value: any}}
 */
export function makeCustomChangeEvent(e) {
  if (!e) {
    console.warn(
      '[commonFormChange]: an event ({target:{name: string, value: any}}) is needed'
    )
    return
  }
  if (Array.isArray(e.target.name))
    return { path: e.target.name, value: e.target.value }
  return { path: e.target.name.split('.'), value: e.target.value }
}
/**
 *
 * @typedef PhoneNumberEvent
 * @property {string} fullPhoneNumber
 * @property {string} cleanedPhone
 * @property {string} countryCode
 * @property {string} dialCode
 * @property {string} name country name
 */

/**
 *
 * @param {string} name name in form, can include dots
 * @param {string} value phone only without dialCode and especial chars
 * @param {import('react-phone-input-2').CountryData} countryData
 * @param {React.ChangeEvent<HTMLInputElement>} e
 * @param {string} fullPhoneNumber
 * @returns {{ target:{name: string, value: PhoneNumberEvent}}}
 */
export function makeCustomChangeEventPhoneNumber(
  name = '',
  value,
  countryData,
  e,
  fullPhoneNumber
) {
  const { format, ...details } = countryData //exclude format

  return {
    target: {
      name,
      value: {
        fullPhoneNumber,
        cleanedPhone: fullPhoneNumber
          .replace(/\D/g, '')
          .slice(details.dialCode.length),
        ...details
      }
    }
  }
}

/**
 *
 * @param {{target:{name: string | array, checked: boolean}}} e
 * @returns {{path: string[], checked: any}}
 */
export function makeCustomCheckChangeEvent(e) {
  if (!e) {
    console.warn(
      '[commonFormChange]: an event ({target:{name: string, value: any}}) is needed'
    )
    return
  }
  if (Array.isArray(e.target.name))
    return { path: e.target.name, checked: e.target.checked }
  return { path: e.target.name.split('.'), checked: e.target.checked }
}

export const noop = () => {}

export const shuffle = (list = []) => sort(() => Math.random() - 0.5, list)

export function isTestAttendance(attendance = {}) {
  return attendance.type === AttendanceTypes.PRUEBA
}

export function prepareAttendances(attendances, currentTime = Date.now()) {
  let currentAttendance = null
  let prevAttendance = null
  let nextAttendance = null

  attendances.forEach(attendance => {
    const { classStart, classEnd } = attendance

    if (currentTime >= classStart && currentTime <= classEnd) {
      currentAttendance = attendance
    } else if (classEnd < currentTime) {
      if (!prevAttendance || classEnd > prevAttendance.classEnd) {
        prevAttendance = attendance
      }
    } else if (classStart > currentTime) {
      if (!nextAttendance || classStart < nextAttendance.classStart) {
        nextAttendance = attendance
      }
    }
  })

  return { currentAttendance, prevAttendance, nextAttendance }
}

export function getAccessTimes(attendance) {
  if (!attendance) return {}

  const gracePeriod = isTestAttendance(attendance)
    ? GracePeriod.test
    : GracePeriod.standard

  const classStart = new Date(attendance.classStart).getTime()
  const classEnd = new Date(attendance.classEnd).getTime()

  return {
    accessStart: classStart - gracePeriod.init * 60 * 1000,
    accessEnd: classEnd + gracePeriod.end * 60 * 1000
  }
}
