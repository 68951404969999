//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import BrightClassfySvg from 'assets/logos/main/BrightClassfy'
import NoWorries from 'assets/svg/NoWorries'
import Welcome from 'assets/svg/Welcome'
import { executeVerification, verifyAccess } from 'api/auth'
import { useNotificationActions } from 'context/NotificationProvider'
import RingsSpinner from 'components/spinners/RingsSpinner'
import { H2, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import DotsSpinner from 'components/spinners/DotsSpinner'
import PasswordInput from 'components/inputs/PasswordInput'
import Tag from 'components/tags/Tag'
import styles from './PasswordReset.module.css'

const MIN_LENGHT = 10

function PasswordReset() {
  const [step, setStep] = useState(0)
  const [password, setPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isExecuting, setIsExecuting] = useState(true)
  const { setSuccessMessage, setErrorMessage } = useNotificationActions()
  const { token } = useParams()

  const execute = () => {
    setIsExecuting(true)
    setStep(0)
    executeVerification(token)
      .then(() => {
        setTimeout(() => {
          setIsExecuting(false)
          setStep(1)
        }, 3000)
      })
      .catch(() =>
        setTimeout(() => {
          setIsExecuting(false)
          setErrorMessage({
            message:
              'Upps!, parece que no te identificamos. Ponte en contacto con soporte'
          })
          setStep(2)
        }, 3000)
      )
  }
  const isInvalidLength = password.length < MIN_LENGHT

  const isInvalidFormat = !confirmedPassword.match(
    /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{10,})/
  )
  const handleSave = useCallback(() => {
    if (!password || !confirmedPassword) {
      setErrorMessage({ message: 'Rellena el formulario' })
      return
    }
    if (isInvalidLength) {
      setErrorMessage({
        message:
          'La nueva contraseña debe tener una longitud de al menos ' +
          MIN_LENGHT +
          ' caracteres'
      })
      return
    }

    if (isInvalidFormat) {
      setErrorMessage({
        message:
          'La nueva contraseña debe contener al menos una minúscula, una mayúscula, un número y un carácter especial.'
      })
      return
    }
    const passwordsDoNotMatch = password !== confirmedPassword
    if (passwordsDoNotMatch) {
      setErrorMessage({ message: 'Las contraseñas no coinciden.' })
      return
    }
    setIsSaving(true)
    verifyAccess(token, password)
      .then(() => {
        setSuccessMessage({ title: 'Contraseña establecida con éxito' })
        setStep(3)
      })
      .catch(() => {
        setErrorMessage({
          message:
            'Error al guardar la contraseña. Por favor, inténtalo de nuevo.'
        })
      })
      .finally(() => setIsSaving(false))
  }, [
    confirmedPassword,
    isInvalidFormat,
    isInvalidLength,
    password,
    setErrorMessage,
    setSuccessMessage,
    token
  ])

  useEffect(() => {
    execute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const disabled = !password || !confirmedPassword || isSaving
  const SVG = step === 3 ? Welcome : NoWorries
  return (
    <main className={styles.main}>
      <Link to='/login'>
        <BrightClassfySvg className={styles.classfyLogo} />
      </Link>
      <div className={styles.images}>
        <SVG className={styles.backgroundSvg} color='var(--sandground)' />
        <SVG className={styles.mainSvg} />
        {isExecuting && (
          <div className={styles.spinner}>
            <RingsSpinner />
          </div>
        )}
      </div>
      <div className={styles.innerContainer}>
        {step === 0 && (
          <>
            <H2 className={styles.h2}>
              Estamos verificando tu <span>identidad</span>
              <DotsSpinner />
            </H2>
          </>
        )}
        {step === 1 && (
          <>
            <H2 className={styles.h2}>¡Bienvenid@!</H2>
            <Paragraph className={styles.paragraph} type='body1Bold'>
              Configura una contraseña con:
            </Paragraph>
            <div className={styles.tags}>
              <Tag label='Una mayúscula' className={styles.tag} />
              <Tag label='Una minúscula' className={styles.tag} />
              <Tag label='Un número' className={styles.tag} />
              <Tag label='Un carácter especial' className={styles.tag} />
              <Tag
                label={`Al menos ${MIN_LENGHT} caracteres`}
                className={styles.tag}
              />
            </div>
            <div className={styles.form}>
              <PasswordInput
                placeholder='Contraseña'
                value={password}
                error={isInvalidLength || isInvalidFormat}
                onChange={e => setPassword(e.target.value)}
              />
              <PasswordInput
                placeholder='Confirma contraseña'
                value={confirmedPassword}
                error={isInvalidLength || isInvalidFormat}
                onChange={e => setConfirmedPassword(e.target.value)}
              />
            </div>
            <Button
              label='Guardar'
              type='accent-secondary'
              loading={isSaving}
              disabled={disabled}
              onClick={handleSave}
            />
          </>
        )}
        {step === 2 && (
          <>
            <H2 className={styles.h2}>
              Lo sentimos, parece que tu <span>enlace</span>
              <br /> para cambiar tu contraseña ha <span>caducado</span>
            </H2>
            <Button
              type='secondary'
              label='Volver a intentar'
              onClick={execute}
            />
          </>
        )}
        {step === 3 && (
          <>
            <H2 className={styles.h2}>¡Ya puedes iniciar sesión!</H2>
            <Link to='/login'>
              <Button label='Acceder' type='secondary' />
            </Link>
          </>
        )}
      </div>
    </main>
  )
}

export default PasswordReset
