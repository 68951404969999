//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Vector from 'assets/icons/Vector'
import Star from 'assets/icons/Star'
import Cross from 'assets/icons/Cross'
import { TicketTypes } from 'utils/constants'
import useSpecialRequest from 'hooks/useSpecialRequest'
import useCustomNavigate from 'hooks/useCustomNavigate'
import { useAuth } from 'context/AuthProvider'
import { useNotificationActions } from 'context/NotificationProvider'
import { checkAttendanceRating, saveRating } from 'api/attendances'
import { createAgoraProposal, findAgoraProposals } from 'api/budgets'
import { H2, H3, H4, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import Modal from 'components/modals/Modal'
import PhoneInput from 'components/inputs/PhoneInput'
import TextArea from 'components/inputs/TextArea'
import AgoraProposal from 'components/cards/subscriptions/AgoraProposal'
import styles from './Rating.module.css'

function makeRatingNotificationMessage(rating) {
  if (rating <= 2) return 'Gracias. Intentaremos mejorar'
  if (rating == 5) return 'Genial, nos alegra que te haya gustado la clase'
  return 'Gracias por tu valoración'
}

function Rating() {
  const { attendanceId } = useParams()
  const { student } = useAuth()
  const navigate = useCustomNavigate()
  const { setErrorMessage, setSuccessMessage } = useNotificationActions()
  const [isRating, setIsRating] = useState(false)
  const [showRatingModal, setShowRatingModal] = useState(false)
  const [rating, setRating] = useState(0)
  const [agoraProposals, setAgoraProposals] = useState([])
  const [isCheckingOwner, setIsCheckingOwner] = useState(true)
  const [isOwner, setIsOwner] = useState(false)
  const {
    form,
    okDisabled,
    isSaving,
    handleFormChange,
    handlePhoneNumberChange,
    handleSave
  } = useSpecialRequest({ type: TicketTypes.BUDGET })

  const handleRate = useCallback(() => {
    setIsRating(true)
    saveRating(attendanceId, { rating })
      .then(res => {
        setShowRatingModal(false)
        setSuccessMessage({ message: makeRatingNotificationMessage(rating) })
      })
      .catch(e => {
        console.error('Error rating attendance: ', e)
        setErrorMessage({ message: e.message })
      })
      .finally(() => setIsRating(false))
  }, [attendanceId, rating, setSuccessMessage, setErrorMessage])

  const handleClose = useCallback(() => {
    if (!rating || isRating) return
    handleRate()
  }, [handleRate, isRating, rating])

  const handleViewProposal = budgetId => navigate('/proposals/' + budgetId)

  const allowContinue = !isCheckingOwner && isOwner

  useEffect(() => {
    return
    checkAttendanceRating(attendanceId)
      .then(({ isOwner, canBeRated }) => {
        setIsOwner(isOwner)
        setShowRatingModal(canBeRated)
      })
      .catch(e => {
        console.error(e.message)
        setIsOwner(false)
      })
      .finally(() => setIsCheckingOwner(false))
  }, [attendanceId])

  useEffect(() => {
    if (!allowContinue) return
    createAgoraProposal()
      .catch(e => console.error('Error auto generating proposal: ', e.message))
      .finally(() =>
        findAgoraProposals({ useCreatedByMe: true })
          .then(setAgoraProposals)
          .catch(_ => null)
      )
  }, [allowContinue])
  return (
    <section className={styles.section}>
      <div className={styles.header}>
        <H2 className={styles.accent}>¡Buen trabajo, {student.shortName}!</H2>
        <H3>
          Esperamos que te haya gustado la clase en Classfy. <br />
        </H3>
      </div>
      <div className={styles.recommendationSection}>
        <H2 className={styles.recommendationTitle}>Nuestra recomendación 🤙</H2>
        <div className={styles.recommendationInnerContainer}>
          {agoraProposals.map(budget => (
            <AgoraProposal
              key={budget.id}
              budget={budget}
              onClick={() => handleViewProposal(budget.id)}
            />
          ))}
          <ContactForm
            form={form}
            isSaving={isSaving}
            okDisabled={okDisabled}
            hasProposals={agoraProposals.length > 0}
            onPhoneNumberChange={handlePhoneNumberChange}
            onFormChange={handleFormChange}
            onSave={handleSave}
          />
        </div>
      </div>
      {showRatingModal && (
        <Modal onCancel={handleClose} showActions={false}>
          <div className={styles.ratingModalContent}>
            <Cross
              className={[
                styles.cross,
                !rating || isRating ? styles.crossDisabled : ''
              ].join(' ')}
              onClick={handleClose}
            />
            <H3 className={styles.accent}>
              ¡Buen trabajo {student.shortName}!
            </H3>
            <H4 className={styles.h4}>
              Muchas gracias por confiar en nosotros.
              <br />
              Evalúa qué te ha parecido la clase 💫
            </H4>
            <div className={styles.ratingSection}>
              {Array(5)
                .fill()
                .map((_, i) => (
                  <div key={i}>
                    <Star
                      className={styles.star}
                      stroke={rating >= i + 1 ? 'var(--seadapted)' : ''}
                      onClick={() => setRating(i + 1)}
                    />
                  </div>
                ))}
              <Button
                label={isRating ? 'Valorando...' : 'Valorar'}
                size='small'
                disabled={!rating || isRating}
                onClick={handleRate}
              />
            </div>
          </div>
        </Modal>
      )}
    </section>
  )
}

export default Rating

function ContactForm({
  form,
  isSaving,
  okDisabled,
  hasProposals,
  onPhoneNumberChange,
  onFormChange,
  onSave
}) {
  return (
    <div className={styles.contact}>
      <Paragraph type='body1Bold' className={styles.paragraph}>
        ¡Ahora nos toca a nosotros! Déjanos tu número y te contactaremos para
        ofrecerte la propuesta que mejor se adapte a tus necesidades.
        <br />
        {hasProposals && (
          <span>Pero primero échale un vistazo a tu izquierda ⬅️</span>
        )}
      </Paragraph>
      <Vector className={styles.vector} />
      <PhoneInput
        value={form.phoneNumber?.fullPhoneNumber}
        onPhoneNumberChange={onPhoneNumberChange}
      />
      <TextArea
        placeholder='Descríbenos tu experiencia y en lo que necesitas ayuda...'
        value={form.description}
        onChange={onFormChange}
      />
      <Button
        label='Pedir propuesta'
        disabled={okDisabled}
        loading={isSaving}
        onClick={onSave}
      />
    </div>
  )
}
