import React from 'react'
import { Link } from 'react-router-dom'
import config from 'config'
import useCustomNavigate from 'hooks/useCustomNavigate'
import { H2, H4Light } from 'components/typography'
import VerticalTecherCard from 'components/cards/teacher/VerticalTecherCard'
import DotsSpinner from 'components/spinners/DotsSpinner'
import Button from 'components/buttons/Button'
import Carousel from './Carousel'
import styles from './TeacherCarousel.module.css'

const TeacherCarousel = ({
  teachers = [],
  canBookAClass = false,
  subjectsById
}) => {
  const navigate = useCustomNavigate()

  return (
    <div className={styles.continer}>
      <div className={styles.teacherHeader}>
        <div>
          <H2>Tus profes 😎</H2>
          <H4Light className={styles.teacherSubheader}>
            Empieza tu clase
          </H4Light>
        </div>
        {canBookAClass && !config.hide && (
          <div className={styles.scheduleClass}>
            <Link to='/newly-registered'>
              <Button
                className={styles.bookingButton}
                label='Agendar clase'
                size='medium'
                type='secondary'
              />
            </Link>
          </div>
        )}
      </div>
      {!teachers.length && (
        <div className={styles.spinner}>
          <DotsSpinner />
        </div>
      )}
      <Carousel
        className={styles.carousel}
        infinite={false}
        autoplay={false}
        focusOnSelect
      >
        {teachers.map((teacher, i) => (
          <div className={styles.slide} key={teacher.id}>
            <VerticalTecherCard
              classNames={{
                container: styles.verticalTeacherContainer,
                innerContainer: styles.verticalTeacherInnerContainer,
                img: styles.verticalTeacherImg,
                name: styles.verticalTeacherName,
                vector: styles.verticalTeacherVector,
                subjects: styles.verticalTeacherSubjects
              }}
              teacher={teacher}
              subjectsById={subjectsById}
              disabled={!teacher.available}
              buttonSize='tiny'
              onClick={() =>
                navigate('/academy', {
                  state: teacher.attendance
                })
              }
            />
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default TeacherCarousel
